import * as E from "fp-ts/lib/Either";
import { Either, fold } from "fp-ts/lib/Either";
import { identity } from "fp-ts/lib/function";
import { none, Option } from "fp-ts/lib/Option";

export const asOption = <L, R>(e: Either<L, Option<R>>): Option<R> =>
  fold<L, Option<R>, Option<R>>((_) => none, identity)(e);

export const orThrow = <L>(onLeft: (e: L) => Error) => <A>(
  e: Either<L, A>
): A => {
  if (E.isLeft(e)) {
    throw onLeft(e.left);
  } else {
    return e.right;
  }
};

export const foldW = <L, A, B, C>(onLeft: (l: L) => B, onRight: (a: A) => C) =>
  E.fold<L, A, B | C>(onLeft, onRight);

export const getValue: <A, B>(ma: E.Either<A, B>) => A | B = foldW(
  identity,
  identity
);
