import { faBan, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import useSwr from "swr";
import { useSecuredApi } from "../../../hooks/useApi";
import { PostApi, PostMeta } from "../../../services/api";
import { CreatePostModal } from "./CreatePostModal";

export const Posts: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const api = useSecuredApi(PostApi);

  const fetchPosts = async () => {
    return await api.allPosts()();
  };

  const { data: eitherPosts, revalidate, isValidating } = useSwr(
    "fetchPosts",
    fetchPosts,
    {
      errorRetryCount: 3,
    }
  );

  const createPost = async (title: string, content: string) => {
    await api.createPost(undefined, { title, content })();
    if (!isValidating) await revalidate();
  };

  const deletePost = async (id: string) => {
    await api.deletePostById(undefined, id)();
    await revalidate();
  };

  const viewPost = async (id: string) => {
    const res = await api.getPostById(undefined, id)();
    if (res._tag === "Right") {
      alert(res.right.content);
    }
  };

  const columns: ColumnsType<PostMeta> = [
    {
      title: <Trans>Title</Trans>,
      dataIndex: "title",
      key: "title",
    },
    {
      title: <Trans>Author</Trans>,
      dataIndex: "author",
      key: "author",
    },
    {
      title: <Trans>Created at</Trans>,
      render: (i: PostMeta) => <>{i.createdAt.toLocaleString()}</>,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (i: PostMeta) => (
        <Space style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faEye} onClick={() => viewPost(i.id)} />
          <FontAwesomeIcon icon={faBan} onClick={() => deletePost(i.id)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>
        <Trans>All questions</Trans>
      </h1>

      <div>
        <Button onClick={() => setOpen(true)}>
          <Trans>Ask a question</Trans>
        </Button>
      </div>

      {eitherPosts && eitherPosts._tag == "Right" ? (
        <Table
          dataSource={eitherPosts.right}
          columns={columns}
          rowKey={(i) => i.id}
        ></Table>
      ) : (
        <Table></Table>
      )}

      <CreatePostModal open={open} setOpen={setOpen} create={createPost} />
    </>
  );
};
