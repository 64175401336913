import { Auth0Provider } from "@auth0/auth0-react";
import { Global } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import { globalStyles } from "./app/styles/globalStyles";
import { LocaleProvider } from "./contexts/locale.context";
import { env } from "./core/env";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <LocaleProvider>
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      redirectUri={window.location.origin}
      cacheLocation={"localstorage"}
    >
      <Global styles={globalStyles} />
      <App />
    </Auth0Provider>
  </LocaleProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
