import { pipe } from "fp-ts/lib/pipeable";
import * as t from "io-ts";
import { failure } from "io-ts/lib/PathReporter";
import { orThrow } from "../framework/fp/either";

const envCodec = t.interface({
  REACT_APP_AUTH0_DOMAIN: t.string,
  REACT_APP_AUTH0_CLIENT_ID: t.string,
  REACT_APP_SERVER_ENDPOINT: t.string,
});

/*
    Typecheck env on app start, or crash
*/
export const env = pipe(
  envCodec.decode(process.env),
  orThrow((errors: t.Errors) => {
    throw new Error(
      `Failed to start application, missing env vars: ${failure(errors).join(
        "\n"
      )}`
    );
  }),
  (a) => ({
    auth0Domain: a.REACT_APP_AUTH0_DOMAIN,
    auth0ClientId: a.REACT_APP_AUTH0_CLIENT_ID,
    serverEndpoint: a.REACT_APP_SERVER_ENDPOINT,
  })
);
