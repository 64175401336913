import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Loading } from "../Loading";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...args
}) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
    })}
    {...args}
  />
);

export default ProtectedRoute;
