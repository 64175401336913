import styled from "@emotion/styled";
import { Header } from "antd/lib/layout/layout";

export const StyledLogo = styled.div`
  height: 40px;
  margin: 16px;
  display: flex;
`;
export const StyledLogoImg = styled.img`
  display: block;
  margin: auto;
`;

export const StyledHeader = styled(Header)`
  padding: 0;
  background: #f0f2f5;
  border-bottom: 1px solid #6161612e;
`;

export const StyledBurger = styled.span`
  cursor: pointer;
  margin-left: 5px;
  padding: 0 15px;
  width: 45px;
`;

export const StyledAuthUser = styled.span`
  margin-right: 15px;
  float: right;
`;
