import { css, SerializedStyles } from "@emotion/react";

export const globalStyles = (): SerializedStyles => css`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5rem;
    width: 100%;
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
