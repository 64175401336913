import { t } from "@lingui/macro";
import { Form, FormInstance, Input, message, Modal } from "antd";
import React from "react";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface CreatePostModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  create: (title: string, content: string) => Promise<any>;
}
export const CreatePostModal: React.FC<CreatePostModalProps> = ({
  open,
  setOpen,
  create,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [title, setTitle] = React.useState<string | null>(null);
  const [content, setContent] = React.useState<string | null>(null);

  const formRef = React.createRef<FormInstance<any>>();

  const handleOk = async () => {
    if (!title || !content) {
      message.error("You must fill in both title and content");
      return;
    }
    formRef.current?.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = async () => {
    if (!title || !content) return;
    setConfirmLoading(true);

    await create(title, content).finally(() => setConfirmLoading(false));

    setOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t`Ask a question`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form
        {...layout}
        ref={formRef}
        name="create-post-modal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input onInput={(a) => setTitle(a.currentTarget.value)} />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          rules={[{ required: true, message: "Please input a content!" }]}
        >
          <Input.TextArea onInput={(a) => setContent(a.currentTarget.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
