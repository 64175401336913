import { pipe } from "fp-ts/lib/pipeable";
import * as TE from "fp-ts/lib/TaskEither";
import * as t from "io-ts";

/*

Making a type-safe fetch client from a declarative API description
For simplicity, we suppose that :
- there is only one success response type
- if codec, the content-type is json , else text / empty (can be improved)
- errors are not processed by the client (response is passed as an Either)

*/

type Verb =
  | "GET"
  | "POST"
  | "PUT"
  | "OPTION"
  | "PATCH"
  | "DELETE"
  | "HEAD"
  | "TRACE";

export interface ApiRoute {
  path: string;
  response?: t.Mixed;
  method?: Verb;
  body?: string;
}

export type ApiDescription = {
  [operation: string]: (...urlParams: any) => ApiRoute;
};

export type Api<T extends ApiDescription> = {
  [K in keyof T]: (
    init?: RequestInit | undefined,
    ...args: Parameters<T[K]>
  ) => TE.TaskEither<
    unknown,
    Exclude<ReturnType<T[K]>["response"], undefined>["_A"]
  >;
};

const buildHandler = <T extends ApiDescription, RouteKey extends keyof T>(
  route: T[RouteKey],
  commonInit?: RequestInit
) => (init?: RequestInit, ...args: Parameters<T[RouteKey]>) => {
  const { path, response, ...fetchOptions } = route(...args);

  return pipe(
    () =>
      fetch(path, {
        ...fetchOptions,
        ...commonInit,
        ...init,
      }),
    TE.fromTask,
    TE.chain((res) => {
      if (response)
        return pipe(
          TE.fromTask(() => res.json()),
          TE.chainW((encoded) => TE.fromEither(response.decode(encoded)))
        );
      else {
        return TE.fromTask(() => res.text());
      }
    })
  );
};

export const makeApi = <T extends ApiDescription>(desc: T) => {
  const res = {} as Api<T>;
  for (const k in desc) {
    res[k] = buildHandler(desc[k]);
  }
  return res;
};
