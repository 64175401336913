import { useAuth0 } from "@auth0/auth0-react";
import {
  faBars,
  faChevronLeft,
  faCog,
  faInbox,
  faQuestionCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Dropdown, Layout, Menu, Select } from "antd";
import Search from "antd/lib/input/Search";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import {
  StyledAuthUser,
  StyledBurger,
  StyledHeader,
  StyledLogo,
  StyledLogoImg,
} from "./AppLayout.styled";

const { Content, Footer, Sider } = Layout;

export const AppLayout: React.FC<{}> = ({ children }) => {
  const { user, isAuthenticated, logout } = useAuth0();

  const { locale, setLocale } = useLocale();

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  if (!isAuthenticated)
    return (
      <>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
      </>
    );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={"290px"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <StyledLogo>
          <StyledLogoImg
            src="https://www.conduktor.io/uploads/conduktor-light.svg"
            alt="Conduktor Logo"
          ></StyledLogoImg>
        </StyledLogo>
        <Menu theme="dark" mode="inline">
          <Menu.Divider />

          <Menu.Item key="11">
            <FontAwesomeIcon icon={faUsers} /> Team settings
          </Menu.Item>
          <Menu.Item key="1">
            <Badge dot style={{ marginRight: 10 }}>
              <FontAwesomeIcon
                icon={faInbox}
                style={{ color: "#a6adb4", marginRight: 10 }}
              />
            </Badge>
            Inbox
          </Menu.Item>
          <Menu.Item key="0" unselectable="on" disabled={true}>
            <Search placeholder="Search anything" />
          </Menu.Item>
          <Menu.Divider />

          <Menu.ItemGroup title="Conduktor web">
            <Menu.Item key="2">nav 2</Menu.Item>
            <Menu.Item key="3">nav 3</Menu.Item>
            <Menu.Item key="4">nav 4</Menu.Item>
            <Menu.Item key="5">nav 5</Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Test automation">
            <Menu.Item key="6">nav 2</Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Monitoring">
            <Menu.Item key="7">nav 2</Menu.Item>
            <Menu.Item key="8">nav 3</Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <div key="9" style={{ position: "fixed", bottom: 0, padding: 20 }}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              style={{ color: "#a6adb4", marginRight: 10 }}
            />
            <Link to="/posts">
              <span style={{ color: "#fff" }}>HELP</span>
            </Link>
          </div>
        </Menu>
      </Sider>
      <Layout>
        <StyledHeader>
          <StyledBurger onClick={toggle}>
            {collapsed ? (
              <FontAwesomeIcon icon={faBars} style={{ marginLeft: 10 }} />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} />
            )}
          </StyledBurger>
          {user && (
            <StyledAuthUser>
              <Select
                defaultValue={locale || "en"}
                style={{ width: 60 }}
                bordered={false}
                onChange={(key) => setLocale(key)}
              >
                <Select.Option value="fr">fr</Select.Option>
                <Select.Option value="en">en</Select.Option>
              </Select>

              <span style={{ marginRight: 5 }}>{user.name} </span>

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="0">
                      <span onClick={() => logout()}>Logout</span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <FontAwesomeIcon icon={faCog} />
              </Dropdown>
            </StyledAuthUser>
          )}
        </StyledHeader>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Conduktor ©2021</Footer>
      </Layout>
    </Layout>
  );
};
