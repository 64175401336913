import * as t from "io-ts";
import { DateFromISOString, UUID } from "io-ts-types";
import { env } from "../core/env";
import { makeApi } from "../framework/api/client";

/*

API definition (used to create a client)

*/
const PostMeta = t.interface({
  id: UUID,
  title: t.string,
  author: t.string,
  published: t.boolean,
  createdAt: DateFromISOString,
});
export type PostMeta = t.TypeOf<typeof PostMeta>;

const Post = t.interface({
  meta: PostMeta,
  content: t.string,
});
export type Post = t.TypeOf<typeof Post>;

const BASE_PATH = env.serverEndpoint + "/posts";

export const PostApi = makeApi({
  createPost: (body: { title: string; content: string }) => ({
    path: BASE_PATH,
    method: "POST",
    body: JSON.stringify(body),
    response: Post,
  }),
  getPostById: (id: string) => ({
    path: `${BASE_PATH}/${id}`,
    method: "GET",
    response: Post,
  }),
  deletePostById: (id: string) => ({
    path: `${BASE_PATH}/${id}`,
    method: "DELETE",
  }),
  allPosts: () => ({
    path: BASE_PATH,
    method: "GET",
    response: t.array(PostMeta),
  }),
});
