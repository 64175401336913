import { useAuth0 } from "@auth0/auth0-react";
import { pipe } from "fp-ts/lib/pipeable";
import * as TE from "fp-ts/lib/TaskEither";
import { Api, ApiDescription } from "../framework/api/client";

export const useSecuredApi = <T extends ApiDescription>(api: Api<T>) => {
  const { getIdTokenClaims } = useAuth0();

  const res = {} as Api<T>;

  //fix eslint issue
  interface Init extends RequestInit {}

  for (const k in api) {
    res[k] = (init?: Init, ...args: Parameters<T[typeof k]>) =>
      pipe(
        () => getIdTokenClaims(),
        TE.fromTask,
        TE.map((t) => t.__raw),
        TE.map((freshToken) => ({
          ...init,
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${freshToken}`,
          },
        })),
        TE.chain((authedInit) => api[k](authedInit, ...args))
      );
  }
  return res;
};
