import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { en, fr } from "make-plural/plurals";
import React, { useEffect, useState } from "react";

const locales = {
  en: "English",
  fr: "Français",
};
export type Locales = typeof locales;
export type Locale = keyof Locales;

export const defaultLocale: Locale = "en";

interface LocaleContext {
  locale: Locale;
  locales: Locales;
  setLocale: (l: Locale) => void;
}
export const initialLocaleContext: LocaleContext = {
  locale: defaultLocale,
  locales,
  setLocale: (_l: Locale) => false,
};

export const LocaleContext = React.createContext(initialLocaleContext);

export const LocaleProvider: React.FC<{}> = ({ children }) => {
  //   const storage = useStorage("locale");

  const [memoLocale, setMemoLocale] = useState<Locale>(defaultLocale);

  const dynamicActivate = async (locale: string) => {
    const { messages } = await import(`../locales/${locale}/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);
  };

  useEffect(() => {
    i18n.loadLocaleData({
      en: { plurals: en },
      fr: { plurals: fr },
    });
    dynamicActivate(defaultLocale);
  }, []);

  const setLocale = (locale: Locale) => {
    setMemoLocale(locale);
    return dynamicActivate(locale);
  };

  const localeContext: LocaleContext = {
    locale: memoLocale,
    locales,
    setLocale,
  };

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <LocaleContext.Provider value={localeContext}>
        {children}
      </LocaleContext.Provider>
    </I18nProvider>
  );
};
