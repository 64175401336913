import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React from "react";

export const Home: React.FC = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <>
      {isAuthenticated ? (
        <Trans>Hello {user.name}</Trans>
      ) : (
        <>
          <div>
            <Trans>You must be logged in to access this resource</Trans>
          </div>
          <div>
            <Button onClick={loginWithRedirect}>Login</Button>
          </div>
        </>
      )}
    </>
  );
};
